import { CommonModule } from '@angular/common';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FoIconComponent } from '../fo-icon/fo-icon.component';

type AlertVariant = 'primary' | 'secondary' | 'success' | 'danger' | 'info' | 'warning';

@Component({
  standalone: true,
  selector: 'fo-alert',
  templateUrl: './fo-alert.component.html',
  styleUrls: ['./fo-alert.component.scss'],
  imports: [
    CommonModule,
    // Commons
    FoIconComponent,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class FoAlertComponent {
  /**
   * List of icons for each variant
   */
  readonly VARIANT_ICON: Record<AlertVariant, string> = {
    primary: 'alert-info',
    secondary: 'alert-info',
    success: 'alert-success',
    danger: 'alert-error',
    info: 'alert-info',
    warning: 'alert-warning',
  };

  @Input() variant: AlertVariant = 'primary';

  constructor() {}
}
